import React, { useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';

import CustomScreen from './components/CustomScreen';
import ListGuestScreen from './components/ListGuestScreen';
import WelcomeScreen from './components/WelcomeScreen';

import * as T from './types';

/**
 * Function to render Link Generator
 * @returns {JSX.Element}
 */
function LinkGenerator() {
  const [displayState, setDisplayState] = useState(T.STATE_DISPLAY.welcome);
  const [mode, setMode] = useState(T.CUSTOM_MODE.invitation);

  const setCustomInvitation = (type) => {
    setDisplayState(T.STATE_DISPLAY.custom);
    setMode(type);
  };

  return (
    <Box>
      {displayState === T.STATE_DISPLAY.list && <ListGuestScreen type={T.CUSTOM_MODE.invitation} />}
      {displayState === T.STATE_DISPLAY.custom && <CustomScreen type={mode} />}
      {displayState === T.STATE_DISPLAY.welcome && (
        <WelcomeScreen onSetCustomInvitation={setCustomInvitation} onSetDisplay={setDisplayState} />
      )}

      {/* Floating Button */}
      {/* Only render component if not in welcome page */}
      {displayState !== T.STATE_DISPLAY.welcome && (
        <IconButton
          colorScheme="whiteAlpha"
          className="animation-heart"
          isRound
          icon={<FaArrowLeft />}
          onClick={() => setDisplayState(T.STATE_DISPLAY.welcome)}
          // custom css styles
          boxShadow="xl"
          position="fixed"
          bottom="24px"
        />
      )}
    </Box>
  );
}

export default LinkGenerator;
