export const BUTTON_PROPS = {
  borderRadius: '24px',
  bgColor: 'rgb(155 104 25 / 77%)',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  size: 'md',
  letterSpacing: '1px',
  isFullWidth: true,
  marginBottom: '16px',
  fontSize: 'sm',
};

export const STATE_DISPLAY = {
  welcome: 'welcome',
  custom: 'custom',
  list: 'list',
};

export const CUSTOM_MODE = {
  invitation: 'invitation',
  announcement: 'announcement',
};

export const TYPE_BROADCAST = {
  ID_GENERAL_INVITATION: 'ID_GENERAL_INVITATION',
  EN_GENERAL_INVITATION: 'EN_GENERAL_INVITATION',
  ID_MUSLIM_INVITATION: 'ID_MUSLIM_INVITATION',
  EN_MUSLIM_INVITATION: 'EN_MUSLIM_INVITATION',
  ID_GENERAL_ANNOUNCEMENT: 'ID_GENERAL_ANNOUNCEMENT',
  EN_GENERAL_ANNOUNCEMENT: 'EN_GENERAL_ANNOUNCEMENT',
  ID_MUSLIM_ANNOUNCEMENT: 'ID_MUSLIM_ANNOUNCEMENT',
  EN_MUSLIM_ANNOUNCEMENT: 'EN_MUSLIM_ANNOUNCEMENT',
  CUSTOM_INVITATION: 'CUSTOM_INVITATION',
  CUSTOM_ANNOUNCEMENT: 'CUSTOM_ANNOUNCEMENT',
};

export const LIST_BROADCAST = [
  {
    name: 'Bahasa Indonesia - Umum',
    value: TYPE_BROADCAST.ID_GENERAL_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Indonesia - Muslim',
    value: TYPE_BROADCAST.ID_MUSLIM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Inggris - Umum',
    value: TYPE_BROADCAST.EN_GENERAL_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Inggris - Muslim',
    value: TYPE_BROADCAST.EN_MUSLIM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Indonesia - Muslim',
    value: TYPE_BROADCAST.ID_MUSLIM_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Indonesia - Umum',
    value: TYPE_BROADCAST.ID_GENERAL_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Inggris - Umum',
    value: TYPE_BROADCAST.EN_GENERAL_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Inggris - Muslim',
    value: TYPE_BROADCAST.EN_MUSLIM_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Custom Broadcast - Announcement',
    value: TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement
  },
  {
    name: 'Custom Broadcast - Invitation',
    value: TYPE_BROADCAST.CUSTOM_INVITATION,
    type: CUSTOM_MODE.invitation
  }
];

export const FORM_LABEL_OPTIONS = {
  fontSize: 'sm',
  color: 'white',
  margin: '8px 0',
};

export const INPUT_PROPS = {
  colorScheme: 'blackAlpha',
  border: 'none',
  size: 'sm',
  bgColor: '#9B6819',
  color: 'white',
  borderRadius: '4px',
  _placeholder: { color: 'whiteAlpha.600' },
};

export const SELECT_PROPS = {
  colorScheme: 'blackAlpha',
  border: 'none',
  size: 'sm',
  bgColor: '#9B6819',
  color: 'white',
  borderRadius: '4px',
};

export const TH_PROPS = {
  fontFamily: 'jost',
  fontSize: 'md',
  padding: '12px 8px'
};